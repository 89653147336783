@import '../_variables';

// <editor-fold desc="SECTIONS">

.section-header {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  .section-title {
    margin-right: auto;

    &:not(.no-border) a {
      &:hover,
      &:active {
        text-decoration: none;
      }
    }
  }

  h1, h2, h3, h4, h5 {
    :not(.section-title) {
      flex-basis: 100%;
    }
  }

  :not(.section-title) {
    margin-top: 0;
  }

  a {
    transform: translateY(-.25rem);
  }
}

@media (@min-screen-md) {
  .section-header {
    h1, h2, h3, h4, h5 {
      flex-basis: auto;
    }

    > :not(:last-child):not(.section-title) {
      margin-right: 1rem;
    }
  }
}

h1, h2, h3, h4, h5 {
  &.section-title {
    display: inline-block;
    padding-right: .5em;
    padding-bottom: 0.2em;
    border-bottom: 2px solid @color-default-5;
  }
}

// </editor-fold>